import React ,{useEffect} from 'react'
import style from './index.module.less'
import { useIntl } from 'react-intl'
import { buy_link,spec_price } from '@/scripts/constant.js'
import getCountry from '@/utils/getCountry.js'
import { useInView } from "react-intersection-observer"
import {  buy_air_openBox} from '@/images/air/index'

const BuyBox = () =>{
    const intl = useIntl()
    const countryCode = getCountry()
    const price_origin = spec_price[countryCode]['air']
    const [btnRef , btnRefInview] = useInView({ threshold: 0 });
    const price_sale = !!buy_link[countryCode]['air'].sale_shop_price?buy_link[countryCode]['air'].sale_shop_price:false
    useEffect(()=>{
        if(!!btnRefInview){
            typeof sensors !== "undefined" &&  sensors.track('module_view', {
                custom: {
                    module_id: 20048
                }
            });
        }
    },[btnRefInview])
    return(
        <div className={style.buy}>
            <div className={style.buyBox}>
                <div className={style.buyBoxImg} style={{backgroundImage:`url(${buy_air_openBox})`}}></div>
                <div className={style.buyBoxContent}>
                    <div className={style.buyBoxContentTitle}>{intl.formatMessage({ id: "air.spec.offer" })}</div>
                    <div className={style.buyBoxContentDesc}>{intl.formatMessage({ id: "air.spec.offer.des" })}</div>
                    <div className={style.buyBoxContentPrice}>
                        {!!price_sale?<>{price_sale}<span>{price_origin}</span></>:price_origin}
                       </div>
                    <div className={style.buyBoxContentBtn}
                    ref={btnRef}
                    onClick={()=>{
                         typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
                            custom: {
                                module_id: 20046
                            }
                        });
                        window.open( buy_link[countryCode]['air'].shop || buy_link[countryCode]['air'].amazon)
                    }} >
                       {intl.formatMessage({ id: "buy_now" })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuyBox