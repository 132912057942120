import React from 'react'
import style from './index.module.less'

const StickyVideo = ({ video_src, text }) => {

    return (
        <div className={style.box}>
            <div className={style.boxVideo}>
                <video autoPlay playsInline loop muted width="100%" >
                    <source src={video_src} type="video/mp4" />
                </video>
            </div>
            <div className={style.boxText}>
                {text.map((item) => (
                    <div className={style.boxTextItem} key={`airStickyVideo${item}`}>{item}</div>
                ))}

            </div>
        </div>
    )
}

export default StickyVideo