import React,{useEffect} from 'react'
import { useIntl } from 'react-intl'
import style from './index.module.less'
import {   accessories1_1,
    accessories1_2,
    accessories2_1,
    accessories2_2,
    accessories3_1,
    accessories3_2,
    accessories4_1,
    accessories4_2,} from '@/images/air/index'
import useBigScreen from "@/hooks/bigScreen"
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
const Accessories = () =>{
    gsap.registerPlugin(ScrollTrigger)

    const intl = useIntl()
    const ifPc = useBigScreen(768)
    const access_arr= [
        {
            img:accessories1_1,
            cover_img:accessories1_2,
            desc:intl.formatMessage({ id: "air.features.pic1.des" })
        },
        {
            img:accessories2_1,
            cover_img:accessories2_2,
            desc:intl.formatMessage({ id: "air.features.pic2.des" })
        },
        {
            img:accessories3_1,
            cover_img:accessories3_2,
            desc:intl.formatMessage({ id: "air.features.pic3.des" })
        },{
            img:accessories4_1,
            cover_img:accessories4_2,
            desc:intl.formatMessage({ id: "air.features.pic4.des" })
        }
    ]
    useEffect(()=>{
        if(!ifPc){
            const imgItems = gsap.utils.toArray(`.air_accessoriesItem`);
            imgItems.forEach(item => {
                gsap.to(item, {
                    opacity: 1,
                    scrollTrigger: {
                        start: "center center",
                        end: "center 35%",
                        trigger: item,
                        toggleActions: 'play reverse play reverse'
                    }
                })
            });
        }
       
    },[ifPc])
    return(
        <div className={style.box}>
            <h2 className={style.title}>{intl.formatMessage({ id: "air.features.title" })}</h2>
            <div className={style.desc}>{intl.formatMessage({ id: "air.features.des" })}</div>
            <div className={style.access}>
                {access_arr.map(item=>(
                    <div className={style.accessItem} key={`air_access_${item.desc}`}>
                        <div className={style.accessItemPic} style={{backgroundImage:`url(${item.img})`}}>
                            <div className={`${style.accessItemPicCover} ${ifPc?'':'air_accessoriesItem'}`} style={{backgroundImage:`url(${item.cover_img})`}}></div>
                        </div>
                        <div className={style.accessItemDesc}>{item.desc}</div>
                    </div>
                ))}
            </div>

        </div>
    )
}

export default Accessories