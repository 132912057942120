import React,{useState, useRef} from 'react'
import style from './index.module.less'
import { useIntl } from 'react-intl'
import SeeMoreIcon from '../../../images/SVG/seemore.svg'

const Specs = () =>{

    const specBoxRef = useRef()
    const specRef = useRef()
    const [ifOpen , setIfOpen] = useState(false)
    const intl = useIntl()
    const spec_list = [
        {
            title: intl.formatMessage({ id: "air.spec.subtitle1" }),
            content: [
                { text:  intl.formatMessage({ id: "air.spec.size1" })},
                { text:  intl.formatMessage({ id: "air.spec.size2" })},
                { text:  intl.formatMessage({ id: "air.spec.size3" })},
            ],
        },
        {
            title:intl.formatMessage({ id: "air.spec.subtitle2" }),
            content: [{ text: intl.formatMessage({ id: "air.spec.weight" }) }]
        },
        {
            title:intl.formatMessage({ id: "air.spec.subtitle3" }),
            content: [
                { text: intl.formatMessage({ id: "air.spec.display1" })},
                { text: intl.formatMessage({ id: "air.spec.display2" }) },
                { text: intl.formatMessage({ id: "air.spec.display3" })},
                { text: intl.formatMessage({ id: "air.spec.display4" })},
                { text: intl.formatMessage({ id: "air.spec.display5" })}
            ],
            tip:intl.formatMessage({ id: "air.spec.display4.expl" })

        },
        {
            title: intl.formatMessage({ id: "air.spec.subtitle4" }),
            content: [
                { text: intl.formatMessage({ id: "air.spec.audio1" })},
                { text: intl.formatMessage({ id: "air.spec.audio2" })}
            ]
        },
        {
            title: intl.formatMessage({ id: "air.spec.subtitle5" }),
            content: [
                { text: intl.formatMessage({ id: "air.spec.design1" }) },
                { text: intl.formatMessage({ id: "air.spec.design2" }) },
                { text: intl.formatMessage({ id: "air.spec.design3" }) }
            ]
        },
        {
            title: intl.formatMessage({ id: "air.spec.subtitle6" }),
            content: [
                { text:  intl.formatMessage({ id: "air.spec.connect" }) },
            ]
        },
        {
            title:  intl.formatMessage({ id: "air.spec.subtitle7" }),
            content: [
                { text:  intl.formatMessage({ id: "air.spec.compa1" }) ,tips:[
                    intl.formatMessage({ id: "air.spec.compa1.expl1" }),
                    intl.formatMessage({ id: "air.spec.compa1.expl2" }),
                ]},
                { text:intl.formatMessage({ id: "air.spec.compa2" })},
            ],
            tip:intl.formatMessage({ id: "air.spec.compa2.expl" })


        },
        {
            title:  intl.formatMessage({ id: "air.spec.subtitle8" }),
            content: [
                { text:intl.formatMessage({ id: "air.spec.button1" })},
                { text:intl.formatMessage({ id: "air.spec.button2" })}
            ]
        },
        {
            title:  intl.formatMessage({ id: "air.spec.subtitle9" }),
            content: [
                { text: intl.formatMessage({ id: "air.spec.certi" }) },
            ]
        },
        {
            title:  intl.formatMessage({ id: "air.spec.subtitle10" }),
            content: [
                { text: intl.formatMessage({ id: "air.spec.warr" })},     
            ]
        },
        {
            title: intl.formatMessage({ id: "air.spec.subtitle11" }),
            content: [
                { text: intl.formatMessage({ id: "air.spec.box1" })},
                { text: intl.formatMessage({ id: "air.spec.box2" })},
                { text: intl.formatMessage({ id: "air.spec.box3" })},
                { text: intl.formatMessage({ id: "air.spec.box4" })},
                { text: intl.formatMessage({ id: "air.spec.box5" })},
                { text: intl.formatMessage({ id: "air.spec.box6" })},
               
            ],
            tip:intl.formatMessage({ id: "air.spec.box6.expl" })

        }
    ]
    const openSpecs = ()=>{
        if(!!specBoxRef.current){
            let boxHeight = specBoxRef.current.clientHeight
            specRef.current.style.height=`${boxHeight}px`
            setIfOpen(true)
        }

        typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
            custom: {
                module_id: 20044
            }
        });
    }
    
    return (
        <div className={style.box}>
            <div className={style.boxTitle}>{intl.formatMessage({ id: "air.spec.title", defaultMessage: "Specs" })}</div>
            <div className={style.spec}  ref={specRef}>
                <div className={style.specCover} style={{opacity:ifOpen?0:1,pointerEvents:ifOpen?'none':'auto'}}>
                    <div className={style.specCoverContent} onClick={openSpecs} >
                    {intl.formatMessage({ id: "ces.specs.viewmore", defaultMessage: "View more" })} <SeeMoreIcon />
                    </div>
                </div>
                <div className={style.specBox} ref={specBoxRef}>
                    {spec_list.map((item, index) => (
                        <div className={style.specItem} key={`specItem${index}`} style={{ backgroundColor: index % 2 !== 0 ? '#fff' : '#F3F3F3' }}>
                            <div className={style.specItemTitle}>{item.title}</div>
                            <ul className={style.specItemContent}>
                                {item.content.map((v, key) => (
                                    <li key={`specItem${index}Content${key}`}>{v.text}
                                        {!!v.tips && <ul>
                                            {v.tips.map((val, k) => (
                                                <li key={`specItem${index}Content${key}-${k}`}>{val}</li>
                                            ))}
                                        </ul>}

                                    </li>
                                ))}
                                {!!item.tip&&<div className={style.specItemContentTip}>{item.tip}</div>}
                                {!!item.icons&&<div className={style.specItemContentIcons}>
                                            {item.icons.map((img,imgIndex)=>(
                                                <img src={img} key={`specItemContentIcons${imgIndex}`} />
                                            ))}
                                </div>}
                            </ul>
   
                        </div>
                    ))}
                </div>

            </div>
        </div>
    )
}

export default Specs