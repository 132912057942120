import React, { useEffect } from "react";
import { Layout, ProFirst, SEO, ProBuy, RedBuyBtn, ProcessNav } from '@/components/index'
import style from './index.module.less'
import { useInView } from "react-intersection-observer"
import { useIntl } from "react-intl"
import { SwiperKol } from '@/components/index'
import {
    proBgAir_mobile, proAir_logo, proAir_sale_logo,
    proBgAir_pc, airBuyPro, airBuyPro_mobile,
    air_video_game,
    air_video_movie,
    air_picscroll_1,
    air_picscroll_2,
    air_picscroll_3,
    air_picscroll_4,
    air_picscroll_5,
    air_picscroll_6,
} from '@/images/air/index'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
    specs_air2pro, specs_air2, specs_air
} from '@/images/air2/index'
import { Spec } from '@/components/Air2/index'
import { kol_data, buy_link, spec_price } from '@/scripts/constant.js'
import { gsap } from 'gsap'
import getCountry from '@/utils/getCountry.js'
import useBigScreen from "@/hooks/bigScreen"
import { StickyVideo, PicScroll, Accessories, BuyBox, BuyAll, Specs } from '@/components/Air'


const Index = () => {
    gsap.registerPlugin(ScrollTrigger)
    const [headerRef, headerRefInView] = useInView({ threshold: 0 });
    const [headerElseRef, headerElseRefInView] = useInView({ threshold: 0 });
    const ifPc = useBigScreen(768)
    const intl = useIntl()
    const countryCode = getCountry()
    const price_origin = spec_price[countryCode]['air']
    const price_sale = !!buy_link[countryCode]['air'].sale_shop_price ? buy_link[countryCode]['air'].sale_shop_price : false
    const [mediaRef, mediaRefInview] = useInView({ threshold: 0 })
    const navList = [
        {
            id: 0,
            title: intl.formatMessage({ id: "progress_air_0" }),
            classname: "air-progress-0",
        },
        {
            id: 1,
            title: intl.formatMessage({ id: "progress_air_1" }),
            classname: "air-progress-1",
        }, {
            id: 2,
            title: intl.formatMessage({ id: "progress_air_2" }),
            classname: "air-progress-2",
        }, {
            id: 3,
            title: intl.formatMessage({ id: "progress_air_3" }),
            classname: "air-progress-3",
        }, {
            id: 4,
            title: intl.formatMessage({ id: "progress_air_4" }),
            classname: "air-progress-4",
        }
    ]
    useEffect(() => {
        window.addEventListener("resize", function () {
            ScrollTrigger.refresh();
        });
    }, [])
    useEffect(() => {
        if (!!mediaRefInview) {
            typeof sensors !== "undefined" && sensors.track('module_view', {
                custom: {
                    module_id: 20049
                }
            });
        }
    }, [mediaRefInview])
    const buyIntro = {
        img: airBuyPro,
        mobileImg: airBuyPro_mobile,
        name: "XREAL Air",
        cate: "Air",
        shop_link: buy_link[countryCode]['air'].shop,
        amazon_link: buy_link[countryCode]['air'].amazon,
        amazon_sale: '',
        sale_shop: !!buy_link[countryCode]['air'].sale_shop_price ? true : false,
    }
    const proFirstMsg = {
        textBlack: false,
        desc: `${intl.formatMessage({ id: "air.frontpage.slogan1" })}<br/>${intl.formatMessage({ id: "air.frontpage.slogan2" })}`,
        logo: !!buy_link[countryCode]['air'].sale_shop_price ? proAir_sale_logo : proAir_logo,
        backgroundColor: "#000",
        pcImg: proBgAir_pc,
        mobileImg: proBgAir_mobile,
        title: "",
        price_origin: price_origin,
        price_sale: price_sale

    }

    const sticky_video_text = [
        intl.formatMessage({ id: "air.largepic1.text1" }),
        intl.formatMessage({ id: "air.largepic1.text2" })
    ]
    const sticky_video_text2 = [
        intl.formatMessage({ id: "air.largepic2.text1" }),
        intl.formatMessage({ id: "air.largepic2.text2" })
    ]
    const pic_scroll_intro1 = [
        {
            img: air_picscroll_1,
            title: intl.formatMessage({ id: "air.collection1.pic1.text" }),
            desc: intl.formatMessage({ id: "air.collection1.pic1.des" })
        },
        {
            img: air_picscroll_2,
            title: intl.formatMessage({ id: "air.collection1.pic2.text" }),
            desc: intl.formatMessage({ id: "air.collection1.pic2.des" })
        },
        {
            img: air_picscroll_3,
            title: intl.formatMessage({ id: "air.collection1.pic3.text" }),
            desc: intl.formatMessage({ id: "air.collection1.pic3.des" })
        }
    ]
    const pic_scroll_intro2 = [
        {
            img: air_picscroll_4,
            title: intl.formatMessage({ id: "air.collection2.pic1.text" }),
            desc: intl.formatMessage({ id: "air.collection2.pic1.des1" })
        },
        {
            img: air_picscroll_5,
            title: intl.formatMessage({ id: "air.collection2.pic2.text" }),
            desc: intl.formatMessage({ id: "air.collection2.pic2.des" })
        },
        {
            img: air_picscroll_6,
            title: intl.formatMessage({ id: "air.collection2.pic3.text" }),
            desc: intl.formatMessage({ id: "air.collection2.pic3.des" })
        }
    ]
    const specList = [
        {
            header: {
                img: specs_air2pro,
                colors: ['#414141'],
                name: 'Air 2 Pro',
                desc: intl.formatMessage({ id: "specs.air2pro.desc" }),
                price: spec_price[countryCode]['air2pro'],
                router: 'air2',
                shop: buy_link[countryCode]['air2pro'].shop || buy_link[countryCode]['air2pro'].amazon,
                pro_name: "air2pro",
                shop_sale: !!buy_link[countryCode]['air2pro'].sale_shop_price ? buy_link[countryCode]['air2pro'].sale_shop_price : false
            },
            list: [
                {
                    label: intl.formatMessage({ id: "specs.air2pro.label01" }),
                    text: intl.formatMessage({ id: "specs.air2pro.text01" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label02" }),
                    text: intl.formatMessage({ id: "specs.air2pro.text02" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label03" }),
                    text: intl.formatMessage({ id: "specs.air2pro.text03" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label04" }),
                    text: intl.formatMessage({ id: "specs.air2pro.text04" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label05" }),
                    text: intl.formatMessage({ id: "specs.air2pro.text05" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label06" }),
                    text: intl.formatMessage({ id: "specs.air2pro.text06" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label07" }),
                    text: intl.formatMessage({ id: "specs.air2pro.text07" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label08" }),
                    text: intl.formatMessage({ id: "specs.air2pro.text08" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label09" }),
                    text: intl.formatMessage({ id: "specs.air2pro.text09" }),
                }
            ]
        },
        {
            header: {
                img: specs_air2,
                colors: ['#FF1B12', '#414141'],
                name: 'Air 2',
                desc: intl.formatMessage({ id: "specs.air2.desc" }),
                price: spec_price[countryCode]['air2'],
                router: 'air2',
                shop: buy_link[countryCode]['air2'].shop || buy_link[countryCode]['air2'].amazon,
                pro_name: "air2",
                shop_sale: !!buy_link[countryCode]['air2'].sale_shop_price ? buy_link[countryCode]['air2'].sale_shop_price : false
            },
            list: [
                {
                    label: intl.formatMessage({ id: "specs.air2pro.label01" }),
                    text: intl.formatMessage({ id: "specs.air2.text01" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label02" }),
                    text: intl.formatMessage({ id: "specs.air2.text02" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label03" }),
                    text: intl.formatMessage({ id: "specs.air2.text03" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label04" }),
                    text: intl.formatMessage({ id: "specs.air2.text04" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label05" }),
                    text: intl.formatMessage({ id: "specs.air2.text05" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label06" }),
                    text: intl.formatMessage({ id: "specs.air2.text06" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label07" }),
                    text: intl.formatMessage({ id: "specs.air2.text07" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label08" }),
                    text: intl.formatMessage({ id: "specs.air2.text08" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label09" }),
                    text: intl.formatMessage({ id: "specs.air2.text09" }),
                }
            ]
        },
        {
            header: {
                img: specs_air,
                colors: ['#414141'],
                name: 'Air',
                desc: intl.formatMessage({ id: "specs.air.desc" }),
                price: spec_price[countryCode]['air'],
                router: 'air',
                shop: buy_link[countryCode]['air'].shop || buy_link[countryCode]['air'].amazon,
                pro_name: "air",
                shop_sale: !!buy_link[countryCode]['air'].sale_shop_price ? buy_link[countryCode]['air'].sale_shop_price : false
            },
            list: [
                {
                    label: intl.formatMessage({ id: "specs.air2pro.label01" }),
                    text: intl.formatMessage({ id: "specs.air.text01" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label02" }),
                    text: intl.formatMessage({ id: "specs.air.text02" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label03" }),
                    text: intl.formatMessage({ id: "specs.air.text03" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label04" }),
                    text: intl.formatMessage({ id: "specs.air.text04" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label05" }),
                    text: intl.formatMessage({ id: "specs.air.text05" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label06" }),
                    text: intl.formatMessage({ id: "specs.air.text06" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label07" }),
                    text: intl.formatMessage({ id: "specs.air.text07" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label08" }),
                    text: intl.formatMessage({ id: "specs.air.text08" }),
                }, {
                    label: intl.formatMessage({ id: "specs.air2pro.label09" }),
                    text: intl.formatMessage({ id: "specs.air.text09" }),
                }
            ]
        },
    ]

    return (

        <Layout showsubmenu={true} menuBlack={!(headerRefInView || headerElseRefInView) ? true : false}
        >
            <SEO page="Air" />
            <ProcessNav list={navList} />
            <RedBuyBtn cate='air' gaName='Air' />
            {countryCode !== 'th' ? <>
                <div ref={headerRef}>
                    <div className='air-progress-0'>
                        <ProFirst msg={proFirstMsg} shop_sale={price_sale} />
                    </div>



                </div>
                <div className='air-progress-1'>
                    <div ref={headerElseRef}>
                        <StickyVideo text={sticky_video_text} video_src={air_video_game} />
                    </div>
                    <PicScroll intro={pic_scroll_intro1} classname='air_pic_scroll_img' sensorId={20042} />
                </div>
                <div className='air-progress-2'>
                    <StickyVideo text={sticky_video_text2} video_src={air_video_movie} />
                    <PicScroll intro={pic_scroll_intro2} classname='air_pic_scroll_img1' sensorId={20043} />
                </div>
                <div className='air-progress-3'>
                    <Accessories />
                    <div className={style.kol}>
                        <h2 className={style.kolTitle}>{intl.formatMessage({ id: "air.trust.title" })}</h2>
                        <div className={style.kolDesc}>{intl.formatMessage({ id: "air.trust.subtitle" })}</div>
                        <div className={style.kolSwipper} ref={mediaRef}>
                            <SwiperKol directionData='to-left' list={kol_data.list1} classname={`air2kol1`} />
                            <div style={{ height: ifPc ? "20px" : "10px" }}></div>
                            <SwiperKol directionData='to-right' list={kol_data.list2} classname={`air2kol2`} />

                        </div>

                    </div>
                </div>
                <div className='air-progress-4'>
                    <Specs />
                    <div id='pro-buy'>
                        {countryCode !== 'jp' && countryCode !== 'kr' && <BuyBox />}
                    </div>


                    <BuyAll />
                </div>
        

            </> :
                <>

                    <div ref={headerRef}>
                        <ProFirst msg={proFirstMsg} shop_sale={true} />
                    </div>

                    <div className={style.kol}>
                        <h2 className={style.kolTitle}>{intl.formatMessage({ id: "air.kol.t" })}</h2>
                        <div className={style.kolSwipper}>
                            <SwiperKol directionData='to-left' list={kol_data.list1} classname={`air2kol1`} />
                            <div style={{ height: ifPc ? "20px" : "10px" }}></div>
                            <SwiperKol directionData='to-right' list={kol_data.list2} classname={`air2kol2`} />

                        </div>

                    </div>
                    <Spec list={specList} cate='Air' />
                    <ProBuy pro_intro={buyIntro} />

                </>
            }



        </Layout>
    )
}

export default Index
