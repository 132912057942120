import React, { useEffect, useRef ,useState} from 'react'
import style from './index.module.less'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useBigScreen from "@/hooks/bigScreen"
import AddCircle from '@/images/SVG/addCircle.svg'

const PicScroll = ({ intro ,classname,sensorId}) => {
    gsap.registerPlugin(ScrollTrigger)
    const boxRef = useRef()
    const picRef = useRef()
    const picWidthRef = useRef()
    const [showIndex, setShowIndex]=useState([])
    const ifPc = useBigScreen(768)


    useEffect(() => {
        const distance = picRef.current.offsetWidth - picWidthRef.current.offsetWidth
        gsap.to(picRef.current, {
            left: `-${distance}px`,
            scrollTrigger: {
                start: "top top",
                end: "bottom bottom",
                scrub: true,
                trigger: boxRef.current
            }
        })
        const imgItems = gsap.utils.toArray(`.${classname}`);
        imgItems.forEach(item => {
            gsap.to(item, {
                x: '100px',
                scrollTrigger: {
                    start: "top top",
                    end: "bottom bottom",
                    scrub: true,
                    trigger: boxRef.current
                }
            })
        });
    }, [ifPc])
    // useEffect(()=>{
    //     window.addEventListener("resize", resizer)
    //     resizer()
    //     // return () => {
    //     //   window.removeEventListener("resize", resizer)
    //     // }
    // },[])
    // const resizer =()=>{
    //     gsap.killTweensOf(picRef.current);
    //     gsap.killTweensOf(`.${classname}`);
    //     const distance = picRef.current.offsetWidth - picWidthRef.current.offsetWidth
    //     gsap.to(picRef.current, {
    //         left: `-${distance}px`,
    //         scrollTrigger: {
    //             start: "top top",
    //             end: "bottom bottom",
    //             scrub: true,
    //             trigger: boxRef.current,
    //         }
    //     })
    //     const imgItems = gsap.utils.toArray(`.${classname}`);
    //     imgItems.forEach(item => {
    //         gsap.to(item, {
    //             x: '100px',
    //             scrollTrigger: {
    //                 start: "top top",
    //                 end: "bottom bottom",
    //                 scrub: true,
    //                 trigger: boxRef.current,
    //             }
    //         })
    //     });
    // }



    const showText =(index)=>{
        setShowIndex((prevList) => {
            if (prevList.includes(index)) {
              return prevList.filter((item) => item !== index);
            } else {
              return [...prevList, index];
            }
          });

          typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
            custom: {
                module_id: sensorId,
                custom_category: `card${index + 1}`
            }
        });
    }
    return (
        <div className={style.box} ref={boxRef}>
            <div className={style.boxSticky}>
                <div className={style.pic} ref={picWidthRef}>
                    <div className={style.picBox} ref={picRef}>
                        {intro.map((item,index) => (
                            <div className={style.picItem}>
                                <div className={`${style.picItemImg} ${classname}`} style={{backgroundImage:`url(${item.img})`}}></div>
                                <div className={style.picItemSpan} style={{opacity:showIndex.includes(index)?0:1}}> {item.title}</div>
                                <div className={style.picItemCover}
                                style={{opacity:showIndex.includes(index)?1:0}}
                                >{item.desc}</div>
                                <div className={style.picItemSVG} onClick={()=>showText(index)}
                                style={{transform:showIndex.includes(index)?'rotate(-45deg)':'rotate(0deg)'}}
                                >
                                    <AddCircle />
                                </div>

                            </div>
                        ))}
                    </div>
                    <div className={style.picItem} style={{ opacity: 0 }}></div>


                </div>
            </div>

        </div>

    )
}
export default PicScroll