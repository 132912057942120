import React, { useEffect, useRef } from "react";
import style from './index.module.less'
import classNames from 'classnames'
import YoutubeIcon from '@/images/icons/youtubeIcon-red.png'
import gsap from "gsap";
import { Draggable } from 'gsap/Draggable'
import useBigScreen from "@/hooks/bigScreen"
import _debounce from "lodash/debounce";


const SwiperKol = ({ list, directionData, classname }) => {
    gsap.registerPlugin(Draggable)

    const ifPC = useBigScreen(768)
    const marqueeRef = useRef()
    const trackRef = useRef()
    //设置滚动方向
    const direction = directionData; // "to-left" | "to-right"
    const directionVal = direction == 'to-left' ? -1 : 1;
    let marqueeProgress = gsap.utils.wrap(0, 1);


    const resizeHandler = useRef(
        _debounce(() => {
            const allItems = document.querySelectorAll(`.${classname}item`);
            let totalX = 0;
            const marquee = marqueeRef.current
            const proxy = document.createElement("div");

            allItems.forEach((item, i) => {
                const itemW = (window.innerWidth > 768) ? 340 : 280;
                gsap.set(item, { x: totalX, width: (itemW - 20) });
                totalX += itemW;
            
            });


            gsap.set([marquee], { xPercent: -50 });
            const stringX = directionVal == -1 ? `-=${totalX}` : `+=${totalX}`;
            const animation = gsap.to(`.${classname}item`, {
                repeat: -1,
                duration: 100,
                x: stringX,
                ease: "none",
                paused: false,
                modifiers: {
                    x: function (x, target) {
                        if (directionVal == -1) {
                            x = ((parseInt(x) - totalX) % totalX) + (totalX - target.offsetWidth);
                        } else {
                            x = parseInt(x) % totalX - target.offsetWidth;
                        }
                        return `${x}px`;
                    }
                }
            });

            animation.restart()
            let marqueeVal = gsap.utils.wrap(0, totalX);

            const dragGsap = Draggable.create(proxy, {
                type: "x",
                trigger: `.${classname}marquee`,
                inertia: true,
                onDrag: () => updateProgress(dragGsap, animation, marqueeVal, totalX),
                onThrowUpdate: () => updateProgress(dragGsap, animation, marqueeVal, totalX)
            });
        }, 300)
    ).current;

    useEffect(() => {
        let totalX = 0;
        const allItems = document.querySelectorAll(`.${classname}item`);
        const proxy = document.createElement("div");

     
        const marquee = marqueeRef.current
        allItems.forEach((item, i) => { 
            const itemW = (window.innerWidth > 768) ? 340 : 280;
            gsap.set(item, { x: totalX, width: (itemW - 20) });
            totalX += itemW;
         
        });

        let marqueeVal = gsap.utils.wrap(0, totalX);

        gsap.set([marquee], { xPercent: -50 });

        const stringX = directionVal == -1 ? `-=${totalX}` : `+=${totalX}`;
        const animation = gsap.to(`.${classname}item`, {
            repeat: -1,
            duration: 100,
            x: stringX,
            ease: "none",
            paused: false,
            modifiers: {
                x: function (x, target) {
                    if (directionVal == -1) {
                        x = ((parseInt(x) - totalX) % totalX) + (totalX - target.offsetWidth);
                    } else {
                        x = parseInt(x) % totalX - target.offsetWidth;
                    }
                    return `${x}px`;
                }
            }
        });
        const dragGsap = Draggable.create(proxy, {
            type: "x",
            trigger: `.${classname}marquee`,
            inertia: true,
            onDrag: () => updateProgress(dragGsap, animation, marqueeVal, totalX),
            onThrowUpdate: () => updateProgress(dragGsap, animation, marqueeVal, totalX)
        });
        animation.play()
        marquee.addEventListener("mouseenter", () => animation.pause());
        marquee.addEventListener("mouseleave", () => animation.play());   

        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, [])

    const updateProgress = (dragGsap, animation, marqueeVal, totalX) => {
        const dragValue = (marqueeVal(dragGsap[0].deltaX * directionVal) / totalX);
        const currentProgressAnim = animation.progress();
        const endProgress = marqueeProgress(currentProgressAnim + dragValue);
        animation.progress(endProgress);

    }


    return (
        <div className={classNames(`${classname}marquee`, style.marquee)} ref={marqueeRef}>
            <div className={classNames("track", style.track)} ref={trackRef}>
                {list.map((item, index) => (

                    <div key={index} className={classNames(style.kolSwipperItem, `${classname}item`)}
                    onClick={()=>window.open(item.link)}

                    >
                        <div className={style.kolSwipperItemImg} style={{ backgroundImage: `url(${item.img})` }}></div>
                        <div className={style.kolSwipperItemContent}>
                            <div className={style.kolSwipperItemContentDesc}>{item.desc}</div>
                            <div className={style.kolSwipperItemContentMes}>
                                <div className={style.kolSwipperItemContentMesL}>
                                    <div className={style.kolSwipperItemContentMesLImg} style={{ backgroundImage: `url(${item.icon})` }}></div>
                                    <span>{item.name}</span>
                                </div>
                                 <img src={YoutubeIcon} />

                            </div>

                        </div>
                    </div>

                ))}

            </div>
        </div>
    )
}

export default SwiperKol
